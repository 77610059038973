<template>
  <v-card flat width="75%" style="margin: auto" class="transaction b-r-16 option">
    <v-row class="p-0 m-0 p-t-36">
      <v-spacer></v-spacer>
      <img v-if="errorCode == '-300' || errorCode == '-400'"
           width="120px"
           src="../../assets/icon/Unfortunately.png"
      >
      <img
        v-else
        width="64px"
        src="../../assets/icon/great.png"
      >
      <v-spacer></v-spacer>
    </v-row>
    <v-card class="m-t-40 b-r-16 m-auto" style="padding: 20px" width="640">
      <v-card-text
        v-if="tool.isNotEmpty(title)"
        class="text-center bold-f f-s-24 p-t-30"
      >
        {{title}}
      </v-card-text>
      <v-card-text>
        {{message}}
      </v-card-text>
    </v-card>
    <v-card-text class="text-center bold-f f-s-24 p-t-30" style="padding: 20px">
      <span v-if="isCountDown" >{{ countDown }}
    </span>
    </v-card-text>
    <v-card-text v-if="this.errorCode === '-400' || this.errorCode === '-300'" class="text-center">
      We are redirecting you to New Image Relief, if not working please click here
    </v-card-text>
    <v-card-text v-if="this.errorCode === '-400' || this.errorCode === '-300'" class="text-center">
<!--        <a href="https://newimagerelief.com/why-you-should-review-your-credit-report/" style="color: #0D47A1">https://newimagerelief.com/why-you-should-review-your-credit-report/</a>-->
    </v-card-text>
  </v-card>
</template>

<script>
import { DataType, Tool } from '@/js/core'

const tool = new Tool()
export default {
  data () {
    return {
      title: '',
      message: '',
      errorCode: '',
      notice: '',
      countDown: 10,
      isCountDown: false,
      countDownTime: 0,
      icon: '',
      tool: tool
    }
  },
  methods: {
    getErrorCode () {
      const code = this.$route.params.errorCode
      if (parseInt(code) < 0) {
        this.notice = 'mdi mdi-emoticon-sad-outline'
      } else if (parseInt(code) > 0 && parseInt(code) < 200) {
        this.notice = 'mdi mdi-emoticon-neutral-outline'
      } else {
        this.notice = 'mdi mdi-emoticon-outline'
      }
      return code
    }
  },
  created () {
    this.errorCode = this.getErrorCode()
    this.$store.commit('setOverlay', false)
    let message = DataType.ERR_CODE[this.errorCode].message

    const parsePersonal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))
    const personal = tool.isEmpty(parsePersonal) ? {} : parsePersonal

    const parsePortfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
    const portfolio = tool.isEmpty(parsePortfolio) ? {} : parsePortfolio

    let phone = personal.cellPhone
    if (tool.isEmpty(phone)) {
      phone = personal.homePhone
    }
    message = message.replace('CUSTOMER_PHONE', tool.formatPhone(phone))
      .replace('PORTFOLIO_NUMBER', tool.formatPhone(portfolio.telephone))
      .replace('PORTFOLIO_EMAIL', portfolio.email)
      .replace('FIELD_CUSTOMER_NAME', personal.firstName)
    this.message = message

    this.title = DataType.ERR_CODE[this.errorCode].title
    this.icon = '../../assets/icon/default.png'
    if (this.errorCode === '-400' || this.errorCode === '-300') {
      this.isCountDown = true
      this.countDownTime = setInterval(() => {
        this.countDown--
        if (this.countDown === 0) {
          this.isCountDown = false
          // window.location.href = 'https://newimagerelief.com/why-you-should-review-your-credit-report/'
          clearInterval(this.countDownTime)
        }
      }, 1000)
    }
  }
}
</script>
